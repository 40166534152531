/* Reset all default styles */
* {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* Flex container for card elements */
.cardContainer {
	display: flex;
	justify-content: space-evenly;
}

/* Error message style */
.error{
  color: red;
  font-weight: bold;
}

/* Price format style */
.priceFormat {
	font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	font-size: 2rem;
	align-content: center;
}

/* Align total text to the right */
.alignRightTotal {
	text-align: end;
}

/* Total text style */
.totalText {
	font-size: 1.4rem;
	font-weight: bold;
}

/* Fill SVG color */
.fillSVG {
	fill: white;
}

/* Bungee font style */
.bungee {
	font-family: bungee, Arial, Helvetica, sans-serif;
}

/* Remove link decoration */
.removeLinkDecor{
  text-decoration: none;
  border: none;
}

/* Page title font style */
.pageTitle {
	font-family: "Amatic SC", cursive;
}
